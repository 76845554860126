@import '~@fortawesome/fontawesome-free/css/all.css';

/* Styles globaux */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  padding: 20px;
}

img {
  max-width: 100%;
  height: auto;
}


/* Mise en page générale */
#features,
#about,
#services,
#testimonials,
#team,
#contact,
#footer,
#portfolio {
  width: 100%; /* Utilise 100% de la largeur disponible */
  padding: 20px;
}

/* Pour les petits écrans (mobiles) */
@media screen and (max-width: 768px) {
  #features,
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer,
  #portfolio {
    padding: 15px;
    width: 100%; /* Garde la largeur à 100% */
  }

  h1, h2, h3 {
    font-size: 1.5rem; /* Taille de police adaptée pour mobile */
  }

  p {
    font-size: 1rem;
  }

  /* Flexbox pour aligner les éléments en colonne */
  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container {
  display: flex;
  flex-wrap: wrap;
  }

  .item {
  flex: 1 1 100%; /* Chaque item prend 100% de la largeur */
  }
}

/* Pour les très petits écrans (moins de 400px) */
@media screen and (max-width: 400px) {
  #features,
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer,
  #portfolio {
    width: 100%; /* Évite d'utiliser plus que 100% pour garder les éléments dans l'écran */
    padding: 10px;
  }

  h1, h2, h3 {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.9rem;
  }

  button {
    font-size: 1rem;
    padding: 10px;
  }
}

img {
  max-width: 100%;
  height: auto;
}
